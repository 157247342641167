/* eslint-disable */
import instance from "@/services/instance.js";
const LogIn = payload => {
  return instance.instance
    .post("/auth/admin", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};

const GetDetails = payload => {
  return instance.instanceToken
    .post("/auth/getDetails", payload)
    .then(function(response) {
      return response;
    })
    .catch(function() {});
};


export default {
  logIn: LogIn,
  getDetails: GetDetails
};
